<template>
  <div>
    <v-card v-if="integrationForm">
      <v-toolbar flat>
        <v-toolbar-title>
          {{ integrationForm.alias }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="secondary" :loading="savingForm" @click="saveForm"><v-icon>save</v-icon></v-btn>
        <v-btn text @click="$emit('close')">X</v-btn>
      </v-toolbar>
      <v-card-text style="max-height: 50vh; overflow-y: auto">
        <v-row class="mt-2">
          <v-col
            cols="12"
            sm="6"
            class="my-0 py-0"
            v-for="field in integrationForm.form.formItems"
            :key="field.id"
          >
            <v-text-field
              v-if="field.type == 'text'"
              @change="formKey++"
              v-model="formResponse[field.value]"
              :label="`${field.name}${field.required ? '*' : ''}`"
              outlined
              dense
            >
              <template v-slot:append>
                <v-tooltip top v-if="field.description">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">help</v-icon>
                  </template>
                  <span style="font-size: 12px">{{ field.description }}</span>
                </v-tooltip>
              </template>
            </v-text-field>
            <v-textarea
              v-else-if="field.type == 'textarea'"
              @change="formKey++"
              v-model="formResponse[field.value]"
              :label="`${field.name}${field.required ? '*' : ''}`"
              outlined
              dense
              auto-grow
              rows="3"
            >
              <template v-slot:append>
                <v-tooltip top v-if="field.description">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">help</v-icon>
                  </template>
                  <span style="font-size: 12px">{{ field.description }}</span>
                </v-tooltip>
              </template>
            </v-textarea>
            <v-checkbox
              v-else-if="field.type == 'checkbox'"
              v-model="formResponse[field.value]"
              :label="`${field.name}${field.required ? '*' : ''}`"
              outlined
              dense
            ></v-checkbox>
            <v-autocomplete
              v-else-if="field.type == 'selection'"
              v-model="formResponse[field.value]"
              :label="`${field.name}${field.required ? '*' : ''}`"
              outlined
              dense
              :items="field.formItemOptions"
              item-text="name"
              item-value="value"
              @change="formKey++"
            >
              <template v-slot:append>
                <v-tooltip top v-if="field.description">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">help</v-icon>
                  </template>
                  <span style="font-size: 12px">{{ field.description }}</span>
                </v-tooltip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["integrationForm"],
  data: () => ({
    formResponse: {},
    savingForm: false
  }),
  watch: {
    "integrationForm.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.load();
        }
      },
    },
  },
  methods: {
    load() {
      this.formResponse = {};
      if (this.integrationForm) {
        console.log(this.integrationForm);
        if (!this.integrationForm.answers) {
          this.integrationForm.form.formItems.forEach((field) => {
            if (
              field.type == "text" ||
              field.type == "textarea" ||
              field.type == "selection"
            ) {
              this.formResponse[field.value] = field.defaultValue;
            } else if (field.type == "checkbox") {
              this.formResponse[field.value] = field.defaultFlag;
            }
          });
        } else {
            this.formResponse = this.integrationForm.answers
        //   for (let i = 0; i < this.integrationForm.form.formItems.length; i++) {
        //     let field = this.integrationForm.form.formItems[i];
        //     let find = this.integrationForm.answers[field.value];
        //     if (find) {
        //       this.formResponse[field.value] = find.value;
        //     } else {
        //       if (
        //         field.type == "text" ||
        //         field.type == "textarea" ||
        //         field.type == "selection"
        //       ) {
        //         this.formResponse[field.value] = field.defaultValue;
        //       } else if (field.type == "checkbox") {
        //         this.formResponse[field.value] = field.defaultFlag;
        //       }
        //     }
        //   }
        }
      }
    },
    async saveForm(){
        this.savingForm = true
        this.integrationForm.answers = this.formResponse
        await this.$API.updateBookingForms(this.integrationForm)
        this.savingForm = false
        this.$emit('saved', this.formResponse)
        // this.formResponse = {}
    }
  },
};
</script>